import React from "react";

import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "./pagination";

type PaginationSelectorProps = {
  total: number;
  currentPage: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  maxDisplayed: number;
  limit: number;
};

const PaginationSelector: React.FC<PaginationSelectorProps> = ({
  currentPage,
  setPage,
  maxDisplayed,
  limit,
  total,
}) => {
  const totalPages = Math.ceil(total / limit);
  console.log({
    currentPage,
    setPage,
    maxDisplayed,
    limit,
    total,
  });

  const getPageNumbers = () => {
    const halfMax = Math.floor(maxDisplayed / 2);
    let startPage = Math.max(currentPage - halfMax, 1);
    const endPage = Math.min(startPage + maxDisplayed - 1, totalPages);

    if (endPage - startPage + 1 < maxDisplayed) {
      startPage = Math.max(endPage - maxDisplayed + 1, 1);
    }

    return Array.from(
      { length: endPage - startPage + 1 },
      (_, i) => startPage + i
    );
  };

  const pageNumbers = getPageNumbers();

  const onPrevious = () => setPage((prev) => Math.max(1, prev - 1));
  const onNext = () => setPage((prev) => Math.min(totalPages, prev + 1));
  const firstPageNumber = pageNumbers[0] ?? 0;
  const lastPageNumber = pageNumbers[pageNumbers.length - 1] ?? 0;

  return (
    <Pagination>
      <PaginationContent>
        {currentPage !== 1 && (
          <PaginationItem role="button" onClick={onPrevious}>
            <PaginationPrevious />
          </PaginationItem>
        )}

        {firstPageNumber > 1 && (
          <>
            <PaginationItem role="button" onClick={() => setPage(1)}>
              <PaginationLink>1</PaginationLink>
            </PaginationItem>
            {firstPageNumber > 2 && <PaginationEllipsis />}
          </>
        )}

        {pageNumbers.map((page) => (
          <PaginationItem
            key={`paginationItem${page}`}
            role="button"
            onClick={() => setPage(page)}
          >
            <PaginationLink isActive={page === currentPage}>
              {page}
            </PaginationLink>
          </PaginationItem>
        ))}

        {lastPageNumber < totalPages && (
          <>
            {lastPageNumber < totalPages - 1 && <PaginationEllipsis />}
            <PaginationItem role="button" onClick={() => setPage(totalPages)}>
              <PaginationLink>{totalPages}</PaginationLink>
            </PaginationItem>
          </>
        )}

        {currentPage !== totalPages && (
          <PaginationItem role="button" onClick={onNext}>
            <PaginationNext />
          </PaginationItem>
        )}
      </PaginationContent>
    </Pagination>
  );
};

export { PaginationSelector };
