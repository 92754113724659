"use client";
import { useState } from "react";

import { Input } from "./input";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "./select";
import { TableCell } from "./table";

const EditableCell = ({
  id,
  field,
  value,
  options,
  isEditing,
  handleCellEdit,
}: {
  id: string;
  field: string;
  value: string;
  options?: string[];
  isEditing: boolean;
  handleCellEdit: (id: string, field: string, value: string) => void;
}) => {
  const [localValue, setLocalValue] = useState(value);

  const handleSave = (newValue?: string) => {
    handleCellEdit(id, field, newValue ? newValue : localValue);
  };

  if (isEditing) {
    return (
      <TableCell onClick={(e) => e.stopPropagation()}>
        {options ? (
          <Select
            value={localValue}
            onValueChange={(newValue) => {
              setLocalValue(newValue);
              handleSave(newValue);
            }}
          >
            <SelectTrigger className="w-full">
              <SelectValue>{localValue}</SelectValue>
            </SelectTrigger>
            <SelectContent>
              {options.map((option) => (
                <SelectItem key={option} value={option}>
                  {option}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        ) : (
          <Input
            value={localValue}
            onChange={(e) => setLocalValue(e.target.value)}
            onBlur={() => {
              handleSave(localValue);
            }}
          />
        )}
      </TableCell>
    );
  }

  return (
    <TableCell className="group">
      <div className="flex items-center justify-between">
        <span>{value}</span>
      </div>
    </TableCell>
  );
};

export { EditableCell };
