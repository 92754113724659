import * as React from "react";

import { ChevronRight } from "lucide-react";

import { Button } from "./button";
import { cn } from "../libs/utils";

type BreadCrumbItem = {
  id: string;
  title?: string;
  onClick: () => void;
};

type IBreadCrumb = {
  items: BreadCrumbItem[];
  selected: string;
};

const BreadCrumb: React.FC<IBreadCrumb> = ({ items, selected }) => {
  const itemsToSelected = React.useMemo(
    () => items.slice(0, items.findIndex((item) => item.id === selected) + 1),
    [items, selected]
  );
  return (
    <div className="mb-4 flex items-center space-x-2">
      {itemsToSelected.map((item, index) => (
        <React.Fragment key={String(item.id + index)}>
          <Button
            className={cn("", {
              "text-primary": item.id === selected,
              "text-foreground/75": item.id !== selected,
            })}
            variant="link"
            onClick={() => item.onClick()}
          >
            {item.title}
          </Button>
          {index !== items.length - 1 && <ChevronRight className="h-4 w-4" />}
        </React.Fragment>
      ))}
    </div>
  );
};

export { BreadCrumb };
