"use client";

import React from "react";

import {
  Grid as GridLoader,
  type GridProps,
  RotatingLines,
  type _Props3,
} from "react-loader-spinner";

const Grid: React.FC<GridProps> = ({ ...props }) => {
  return (
    <GridLoader
      visible={true}
      height="80"
      width="80"
      color="var(--color-primary)"
      ariaLabel="grid-loading"
      radius="12.5"
      wrapperStyle={{}}
      wrapperClass="grid-wrapper"
      {...props}
    />
  );
};

const Spinner: React.FC<_Props3> = ({ ...props }) => {
  return (
    <RotatingLines
      visible={true}
      width="80"
      strokeWidth="5"
      animationDuration="0.75"
      ariaLabel="rotating-lines-loading"
      strokeColor="black"
      {...props}
    />
  );
};
export { Grid, Spinner };
