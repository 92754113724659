"use client";

import React from "react";

type Message = {
  from: string;
  content: string;
};

const Messages: React.FC<{ messages: Message[] }> = ({ messages }) => {
  return (
    <div className="flex h-full w-full flex-col gap-1 pb-10">
      {messages.map((message, index) => (
        <div
          key={index}
          className={`flex flex-col gap-1 ${
            message.from === "user" ? "items-end" : "items-start"
          }`}
        >
          <div
            className={`max-w-[300px] break-all rounded-lg px-3 py-2 text-xs font-normal ${
              message.from === "user"
                ? "bg-primary text-white"
                : "bg-slate-200 text-black"
            }`}
          >
            {message.content}
          </div>
        </div>
      ))}
    </div>
  );
};

export { Messages };
